import {floatToFixed} from "@/utils/string";

export default {
  getProducts: (state) => {
    return state.sale.products;
  },
  getSale: (state) => {
    return state.sale;
  },
  getDeposit : (state) => state.deposit,
  getCustomer: (state) => {
    return state.customer;
  },
  getBooking: (state) => {
    return state.booking;
  },
  getQuoteItems: (state) => {
    return state.quoteItems;
  },
  showModal: (state) => {
    return state.showModal;
  },
  getRestToPay: (state) => {
    const roundToTwo = (num) => parseFloat(num.toFixed(2));

    const calculateTotal = () => {
      return state.sale.products.reduce((total, product) => {
        if (product.price) {
          return total + parseFloat(product.price) * product.quantity;
        }
        return total;
      }, 0);
    };

    const calculatePaymentsSum = () => {
      return state.sale.payments.reduce((sum, payment) => {
        return sum + parseFloat(payment.value);
      }, 0);
    };

    let total = calculateTotal();

    total -= (total * state.deposit) / 100;

    total = roundToTwo(total);

    const sum = roundToTwo(calculatePaymentsSum());

    return total > sum ? roundToTwo(total - sum) : 0;
  },
  getTotalPrices: (state) => {
    let sum = 0;
    for (const product of state.sale.products) {
      sum = sum + parseFloat((parseFloat(product.price) * product.quantity));
    }
    return sum;
  },
  getTotalPayments: (state) => {
    let sum = 0;
    for (const payment of state.sale.payments) {
      sum = sum + parseFloat(payment.value);
    }
    return sum;
  },
  getPayments: (state) => {
    return state.sale.payments;
  },
  getMethod: (state) => {
    return state.method;
  },
  getTapeValue: (state) => {
    return state.tapeValue;
  },
  getRendu: (state) => {
    return state.rendu;
  },
  getTickets: (state) => {
    return state.tickets;
  }
}
