import store from "@plugins/store";
import {toFloatFixed} from "@/utils/string";
import Product from "@/classes/nextore/Product";
import Booking from "@/classes/you-jump/Booking";
import {_get} from "@api/doinsport/services/httpService";
import {cloneData, fromIriReferenceToId, hydrate, randomString} from "@/utils/form";
import {arrayMap, isNotUndefinedAndNotNull, refine} from "@/utils/classes";
import {getCurrentDate} from "@/utils/trampoline-park";

export default {
  addProduct: (state, payload) => {
    if (state.tapeValue !== 0 && state.tapeValue !== null) {
      payload.nbArticle = state.tapeValue;
    } else {
      payload.nbArticle = 1;
    }
    state.sale.products.push(payload);
    state.showModal = true;
  },
  updateProductOrderItem: (state, payload) => {
    const productSale = state.sale.products.find(el => el.uniqId === payload.id);
    productSale.orderItem = payload.item;
  },
  setDeposit: (state, payload) => state.deposit = payload,
  updateProductQuote: (state, payload) => {
    let productSale = state.sale.products.find(el => el.uniqId === payload.id);
    productSale = payload.item;
  },
  updateProduct: (state, payload) => {
    const productSale = state.sale.products.find(el => el.id === payload.id);

    if (state.tapeValue !== 0 && state.tapeValue !== null) {
      productSale.nbArticle += state.tapeValue;
    } else {
      productSale.nbArticle++;
    }

    productSale.quantity++;
  },
  refreshProduct: (state, payload) => {
    const productSale = state.sale.products.find(el => el.id === payload.id && el.booking && (el.booking.id === payload.booking.id));

    if (isNotUndefinedAndNotNull(productSale)) {
      for (const key of Object.keys(payload)) {
        productSale[key] = payload[key];
      }
    } else {
      console.eror('Eroor happens when updating product', payload, state.sale);
    }
  },
  refreshProductById: (state, payload) => {
    const productSale = state.sale.products.find(el => el.id === payload.id);

    if (isNotUndefinedAndNotNull(productSale)) {
      for (const key of Object.keys(payload)) {
        productSale[key] = payload[key];
      }
    } else {
      console.eror('Eroor happens when updating product', payload, state.sale);
    }
  },
  addPayment: (state, payload) => {
    let amount = 0;
    state.sale.products.forEach(product => {
      amount = amount + (product.price * product.quantity);
    });
    state.sale.payments.forEach(payment => {
      amount = amount - payment.value;
    });

    if (parseFloat(state.tapeValue) > 0 && state.tapeValue >= amount) {
      payload.value = parseFloat(amount).toFixed(2);
      state.rendu = state.tapeValue - amount;
    } else {
      if (parseFloat(state.tapeValue) > 0) {
        payload.value = parseFloat(state.tapeValue).toFixed(2);
      } else {
        payload.value = parseFloat(amount).toFixed(2);
      }
      state.rendu = 0;
    }

    state.sale.payments.push(payload);
    state.tapeValue = 0;
  },
  setMethod: (state, payload) => {
    state.method = payload;
  },
  setBooking: (state, payload) => {
    state.booking = payload;
  },
  setCustomer: (state, payload) => {
    state.customer = payload;
  },
  setShowModal: (state, payload) => {
    state.showModal = payload;
  },
  removeProduct: (state, payload) => {
    state.sale.products.splice(payload, 1);
  },
  removeProducts: (state) => {
    state.sale.products = [];
  },
  clearSale: (state, payload) => {
    state.sale.products = [];
    state.sale.payments = [];
    state.sale.orderQuote = null;
    state.customer = null;
    state.booking = null;
    state.showModal = false;
    store.commit('quote/reset');
  },
  updatePrice: (state, payload) => {
    if (state.sale.products[payload]) {
      state.sale.products[payload].price = parseFloat(state.tapeValue).toFixed(2);
    }
    state.tapeValue = 0;
  },
  updateQuantity: (state, payload) => {
    if (state.sale.products[payload]) {
      state.sale.products[payload].quantity = parseFloat(state.tapeValue).toFixed(2);
    }
    state.tapeValue = 0;
  },
  setTapeValue: (state, payload) => {
    state.tapeValue = payload;
  },
  saveQuoteItems: (state, items) => {
    state.quoteItems = items;
  },
  fromItemsToProducts: (state, payload) => {
    let items = payload.items;
    const from = payload.from;
    const order = payload.order;

    let requests = [];
    let bookings = [];
    let bookingProducts = [];

    state.quoteItems = items;

    if (from !== 'planning') {
      state.sale.products = [];
      state.sale.payments = [];
    }

    for (const item of items) {
      let product = new Product();
      const nextoreProduct = store.getters['caisse/getNextoreProducts'].find(el => el.id === item.nextoreProductId);
      product.id = item.nextoreProductId;
      product.name = item.title;
      product.booking = item.booking;
      product.order = order;
      product.orderItem = item;
      product.taxeRate = isNotUndefinedAndNotNull(nextoreProduct) ? parseInt(nextoreProduct.tax_rate) : null;
      product.price = toFloatFixed(item.unitPrice);
      product.quantity = item.quantity;
      product.isTicket = false;

      const aTicketProduct = store.getters['cashRegistry/getTickets'].find(el => el.nextoreProductReference === item.nextoreProductId);
      if (isNotUndefinedAndNotNull(aTicketProduct)) {
        product.isTicket = true;
        product.ticketDetails = aTicketProduct;
      }
      product.isInstalment = item.isInstalment;
      product.instalment = item.instalment;

      if (null === item.booking) {
        store.commit('nextore/addProduct', product);
      } else if (item.product.type !== 'playgroundOption') {
        const checkIfUndefined = requests.find(el => el.iri === item.booking['@id']);

        if ('undefined' === typeof checkIfUndefined) {
          bookingProducts.push(product)
          requests.push({iri: item.booking['@id'], request: _get(item.booking['@id'])});
        }
      }
    }

    const bookingRequests = arrayMap(requests, (_, obj) => obj.request);

    Promise.all(bookingRequests)
      .then((results) => bookings = results.map(result => result.data))
      .finally(() => {
        store.commit('quote/setQuoteBookings', bookings);

        for (const item of bookingProducts) {
          if (item.booking) {
            const findBooking = bookings.find(el => el['@id'] === item.booking['@id']);

            if (isNotUndefinedAndNotNull(findBooking)) {
              if (from === 'planning') {
                if (findBooking.client) {
                  state.customer = findBooking.client;
                }
              } else {
                if (state.customer === null && findBooking.client) {
                  state.customer = findBooking.client;
                }
              }

              const timetableBlockPrice = store.getters['cashRegistry/getBlockPrices'].find(el => el['@id'] === findBooking.timetableBlockPrice['@id']);

              item.blockPriceId = timetableBlockPrice.id;
              item.blockPrice = timetableBlockPrice;
              item.name = timetableBlockPrice.name;
              item.isBlockPrice = true;
              item.client = findBooking.client;
              item.quantity = 1;
              item.block = null;
              item.options = [];
              item.slotDate = getCurrentDate(findBooking.startAt);
              item.slots = [];
              item.participantCategory = null
              item.isBlockPrice = true;
              item.ticket = null;

              item.booking = new Booking(null, {assoc: true, blockPrice: timetableBlockPrice});

              hydrate(item.booking, findBooking);
              refine(item.booking);

              item.playgrounds = findBooking.playgrounds;
              item.booking.playgroundOptions = findBooking.playgroundOptions;

              if (findBooking.playgroundOptions) {
                for (const playgroundOption of item.booking.playgroundOptions) {
                  const id = fromIriReferenceToId('/clubs/playgrounds/options/', playgroundOption.option['@id']);
                  const optionItem = items.find((x) => x.nextoreProductId === playgroundOption.option.nextoreProductReference);

                  let clonedOptionDetails = cloneData(playgroundOption.option);

                  clonedOptionDetails.id = id;
                  clonedOptionDetails.quantity = playgroundOption.quantity;
                  clonedOptionDetails.price = optionItem.price / playgroundOption.quantity;
                  playgroundOption.price = optionItem.price;

                  if (item.isInstalment) {
                    clonedOptionDetails.price = items.find((x) => x.product && x.product.id === id).instalment;
                    playgroundOption.price = items.find((x) => x.product && x.product.id === id).instalment;
                  }

                  const findProduct = items.find((x) => x.product && x.product.id === id);

                  if (isNotUndefinedAndNotNull(findProduct)) {
                    if (findProduct.instalment) {
                      clonedOptionDetails.instalment = findProduct.instalment;
                    }
                  }


                  item.options.push(clonedOptionDetails);
                }
              }
              item.order = findBooking.order;
              item.participantsCount = findBooking.participantsCount;
              item.price = toFloatFixed(item.isInstalment ? item.instalment : item.order.amount);
              item.participants = [];
              item.booking.participants = [];
              let category = null;

              for (const participant of findBooking.participants) {
                if (false === participant.canceled) {
                  item.participants.push(
                    {
                      id: participant.id,
                      client: participant.client ? participant.client['@id'] : null,
                      category: participant.category,
                      dataClient: participant.client,
                      updated: false,
                      dataUnderAccount: null,
                    })
                  ;

                  if (participant.category && null === category) {
                    category = participant.category;
                  }

                  item.booking.participants.push(
                    {
                      mainParticipant: participant.mainParticipant,
                      client: participant.client ? participant.client['@id'] : null,
                      booking: findBooking['@id'],
                      category: participant.category,
                      clientEticket: null // TODO
                    })
                  ;
                }
              }

              if (null !== category) {
                const participantCategory = store.getters["cashRegistry/getParticipantCategories"].find(el => el.category['@id'] === category && el.blockPrice === `/clubs/playgrounds/timetables/blocks/prices/${item.blockPriceId}`);
                participantCategory.pricePerParticipant = item.isInstalment ? items.find((x) => x.nextoreProductId === participantCategory.nextoreProductReference).instalment / item.participants.length : participantCategory.pricePerParticipant;
                for (const participant of item.participants) {
                  const orderItem = items.find((x) => x.nextoreProductId === participantCategory.nextoreProductReference);
                  participant.item = orderItem;
                }
                item.participantCategory = isNotUndefinedAndNotNull(participantCategory) ? participantCategory : null;
              }

              store.commit('nextore/addProduct', item);
            }
          }
        }
      })
    ;
  },
  setRendu: (state, payload) => {
    state.rendu = payload;
  },
  setOrderQuote: (state, payload) => {
    state.sale.orderQuote = payload;
  },
  saveState: (state) => {
    const clonedState = cloneData(state);

    clonedState.id = randomString();
    clonedState.creationDate = new Date();

    state.tickets.push(clonedState);
    localStorage.setItem('nextoreTickets', JSON.stringify(state.tickets));

    resetState(state);
  },
  updateNextoreTickets: (state, tickets) => {
    state.tickets = tickets;
    localStorage.setItem('nextoreTickets', JSON.stringify(tickets));
  },
  cancelLastTicket: (state) => {
    state.tickets.splice(state.tickets.length - 1, 1);
    localStorage.setItem('nextoreTickets', JSON.stringify(state.tickets));
  },
  restoreState: (state, payload) => {
    state.sale = payload.ticket.sale;

    store.commit('nextore/setOrderQuote', payload.ticket.sale.orderQuote);
    store.commit('quote/setOrderQuote', payload.ticket.sale.orderQuote);

    state.method = payload.ticket.method;
    state.tapeValue = payload.ticket.tapeValue;
    state.rendu = payload.ticket.rendu;
    state.customer = payload.ticket.customer;
    state.booking = payload.ticket.booking;
    state.creationDate = payload.ticket.creationDate;
    state.showModal = payload.ticket.showModal;

    const findTicket = state.tickets.find(el => el.id === payload.id);
    state.tickets.splice(state.tickets.indexOf(findTicket), 1);

    localStorage.setItem('nextoreTickets', JSON.stringify(state.tickets));
  }
}
const resetState = (state) => {
  store.commit('quote/reset');

  state.sale = {
    products: [],
    payments: [],
    orderQuote: null,
  };

  state.method = null;
  state.tapeValue = 0;
  state.rendu = 0;
  state.customer = null;
  state.creationDate = null;
  state.booking = null;
  state.showModal = false;
};
